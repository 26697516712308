import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteChildrenProps } from 'react-router-dom';
import Button from 'components/Buttons/Button';
import { History } from 'history';
import Routes from 'routes';
import { Formik } from 'formik';
import {
  getConfigVar,
  Pharmacy,
  Account,
  Patient,
  DEFAULT_PHARMACY,
  GetPharmaciesByLocationAPIResponse,
  PharmacyWithDistance,
  pharmacyDetailsSchema,
} from '@avicennapharmacy/managemymeds-shared';
import {
  Checkbox, DetailsWrapper, DetailWrapper, Title, ErrorWrapper,
} from 'components/Form';
import BorderWrapper from 'components/BorderWrapper';
import styled from 'styled-components';
import Axios from 'axios';
import PharmacySearch from 'components/Search/PharmacySearch';
import GoogleMap, { MapMarker } from 'components/GoogleMap';
import * as pharmacyServicesActions from 'redux/actions/pharmacyServices';
import { multiLineString } from 'components/Search/Shared';
import { trackEvent } from 'utils/applicationInsights';
import { AlertFunctionProps, alertFunctions } from 'redux/actions/alert';
import Typography from 'components/Typography';
import useCurrentLocation from 'hooks/useCurrentLocation';
import useStateWithLocalStorage from 'hooks/useStateWithLocalStorage';
import {
  storageKeys,
  storageType,
  regVersions,
} from 'constants/registration';
import { GlobalState } from 'redux/reducers';
import * as userActions from 'redux/actions/user';
import PanelMessage from 'components/PanelMessage';
import Space from 'components/Space';
import { defaultLatLng } from './utils';
import {
  ButtonWrapper, StyledForm, RegisterInnerPageProps,
} from '../../components/Shared';
import LayoutPrivate from '../../../../components/Layout';

const FormLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 20px;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    flex-direction: row;
  }
`;

const DeselectButtonWrapper = styled.div`
  width: 100%;
`;

const MapWrapper = styled(DetailsWrapper)`
  margin-bottom: 0px;
`;

const CheckboxWrapper = styled(BorderWrapper)`
  margin: 30px;
  padding: 15px;
  width: auto !important;
  font-weight: bold;
`;

type PharmacyWithNomination = {
  canChangePharmacyNomination: boolean;
} & Pharmacy;

type UpdatePharmacyProps = {
  isChangePharmacy?: boolean;
};

type SelectPharmacyFormProps = {
  account: Account;
  pharmacy: Pharmacy;
  patient: Patient;
  updatePatient: (patient: Patient, regVersion: string, isPharmacyOnly?: boolean, isRegCall?: boolean) => void;
  updatePharmacyDetails: (pharmacy: Pharmacy) => void;
  updatePatientDetails: (patient: Patient) => void;
  fetchBookings: () => void;
  fetchServices: () => void;
  resetTemporaryStates: () => void;
  history: History;
  registration?: boolean | undefined;
  invalidAccount: boolean | undefined;
  preSelectedPharmacyId: string | null;
  msg?: string | undefined;
  loading: boolean;
  isPharmacyUpdated: boolean;
  isPatientUpdating: boolean;
} & RegisterInnerPageProps &
AlertFunctionProps &
RouteChildrenProps;

const SelectPharmacyForm = ({
  pharmacy,
  patient,
  updatePatient,
  updatePharmacyDetails,
  hideAlert,
  showErrorAlert,
  resetTemporaryStates,
  history,
  registration,
  invalidAccount,
  preSelectedPharmacyId,
  msg,
  isPatientUpdating,
  location: propsLocation,
}: SelectPharmacyFormProps) => {
  const { state }: { state?: UpdatePharmacyProps | null } = propsLocation;
  const [apiErrors, setApiErrors] = useState<string[]>([]);
  const [allPharmacies, setAllPharmacies] = useState<Pharmacy[]>([]);
  const [mapMarkers, setMapMarkers] = useState<MapMarker[]>([]);
  const [location] = useCurrentLocation();
  const [, setPharmacyIdValue] = useStateWithLocalStorage(storageKeys.PHARMACY_ID, storageType.DEFAULT);

  const getAllPharmacies = async (latlong: {}) => {
    const { data } = await Axios.post<GetPharmaciesByLocationAPIResponse>(
      getConfigVar('getPharmaciesByLocationAnonEndpoint'),
      {
        tag: '',
        postcode: '',
        distance: 1000, // capture all pharmacies on the map for now.
        ...latlong,
      },
    );
    return data.pharmacies.filter((p) => p.visibleOnMap).map((p) => ({ ...p, id: p.pharmacyId }));
  };

  useEffect(() => {
    const initialiseMapMarkers = async () => {
      let pharmacies: PharmacyWithDistance[] = [];
      try {
        // this should use users geolocation.
        pharmacies = await getAllPharmacies(Object.keys(location).length ? location : defaultLatLng);
      } catch (error) {
        // If geolocation could not be found/not defined in the used browser try again with a known long/lat.
        if (error.response.status === 422) {
          try {
            pharmacies = await getAllPharmacies(defaultLatLng);
          } catch (innerError) {
            pharmacies = [];
            showErrorAlert('Unable to load pharmacies at this time. Please try again');
          }
        }
      }

      const markers = pharmacies.map(
        ({
          pharmacyId, latitude, longitude, businessName, address1, address2, address3, city, postcode,
        }) => ({
          key: pharmacyId,
          lat: latitude!,
          lng: longitude!,
          info: multiLineString([businessName, address1, address2, address3, city, postcode]),
        }),
      );

      setAllPharmacies(pharmacies);
      setMapMarkers(markers);
      if (preSelectedPharmacyId) {
        const matchingPharmacy = pharmacies.find((p) => p.id === preSelectedPharmacyId);
        if (matchingPharmacy) {
          updatePharmacyDetails(matchingPharmacy);
        }
      }
    };
    initialiseMapMarkers();
  }, [location, showErrorAlert]);

  useEffect(() => {
    if (patient.pharmacyId && !state?.isChangePharmacy) {
      history.push(Routes.HOME);
      resetTemporaryStates();
    }
  }, [patient.pharmacyId]);

  return (
    <LayoutPrivate noPharmacy={registration}>
      {msg && (
        <>
          <Space size={10} />
          <Typography fontStyle="bodyBold" margin>
            {`${msg}, please choose a Pharmacy to continue`}.
          </Typography>
        </>
      )}
      {invalidAccount && (
        <PanelMessage
          type="error"
          message="Invalid account, please try again."
        />
      )}
      {registration && (
        <>
          <Space size={30} />
          <Typography fontStyle="h2" margin style={{ textAlign: 'center' }}>
            Select Pharmacy
          </Typography>
        </>
      )}
      <Formik
        enableReinitialize
        initialValues={{ ...pharmacy, canChangePharmacyNomination: !!pharmacy?.businessName }}
        validationSchema={pharmacyDetailsSchema}
        onSubmit={async (updatedPharmacy: PharmacyWithNomination, { setSubmitting }) => {
          setSubmitting(true);
          setApiErrors([]);
          hideAlert();
          setPharmacyIdValue(updatedPharmacy.id);
          trackEvent('SetPharmacyInStorage');
          updatePharmacyDetails(updatedPharmacy);
          try {
            setSubmitting(true);
            updatePatient(
              {
                ...patient,
                pharmacyId: updatedPharmacy?.id!,
              },
              regVersions.V4Manual,
              true,
              true,
            );
            trackEvent('ChangePharmacy');
            setSubmitting(false);
            return;
          } catch (error) {
            setSubmitting(false);
            trackEvent('ChangePharmacyError', { error });
            window.scrollTo(0, 0);
            if (error.response.status === 422) {
              showErrorAlert(error.response.data.message);
            } else {
              showErrorAlert('Unable to change pharmacy at this time. Please try again.');
            }
            throw error;
          }
        }}
      >
        {({
          values, isSubmitting, handleChange, submitForm, setValues,
        }) => (
          <StyledForm aria-label="select-pharmacy-form">
            <FormLayoutWrapper>
              {values.businessName ? (
                <DetailsWrapper>
                  <DetailWrapper>
                    <Title minWidth>Pharmacy</Title>
                    <BorderWrapper padding>
                      <Typography fontStyle="body">{values.businessName}</Typography>
                    </BorderWrapper>
                  </DetailWrapper>
                  <DetailWrapper>
                    <Title minWidth>Address</Title>
                    <BorderWrapper padding>
                      <Typography fontStyle="body">{values.address1}</Typography>
                      <Typography fontStyle="body">{values.address2}</Typography>
                      <Typography fontStyle="body">{values.address3}</Typography>
                      <Typography fontStyle="body">{values.city}</Typography>
                      <Typography fontStyle="body">{values.postcode}</Typography>
                    </BorderWrapper>
                  </DetailWrapper>
                  <DetailWrapper>
                    <Title minWidth>Telephone</Title>
                    <BorderWrapper padding>
                      <Typography fontStyle="body">{values.telephoneNumber}</Typography>
                    </BorderWrapper>
                  </DetailWrapper>
                  <DetailWrapper>
                    <Title minWidth />
                    <DeselectButtonWrapper>
                      <Button
                        option="secondary"
                        disabled={isSubmitting}
                        onClick={() => setValues(
                          {
                            ...DEFAULT_PHARMACY,
                            canChangePharmacyNomination: false,
                          },
                          true,
                        )}
                      >
                        Selected the wrong pharmacy?
                      </Button>
                    </DeselectButtonWrapper>
                  </DetailWrapper>
                </DetailsWrapper>
              ) : (
                <DetailsWrapper>
                  <DetailWrapper>
                    <Title minWidth>Find pharmacy</Title>
                    <PharmacySearch
                      loading={isSubmitting}
                      select={(newPharmacy: Pharmacy) => {
                        setValues(
                          {
                            ...newPharmacy,
                            canChangePharmacyNomination: values.canChangePharmacyNomination,
                          },
                          true,
                        );
                      }}
                      allPharmacies={allPharmacies}
                    />
                  </DetailWrapper>
                </DetailsWrapper>
              )}
              <MapWrapper>
                <GoogleMap
                  markers={mapMarkers}
                  selectedMarker={mapMarkers.find(({ key }) => values.id === key)}
                  onMarkerClick={(markerKey: string) => {
                    const selectedPharmacy = allPharmacies.find(({ id }) => id === markerKey && values.id !== id) || DEFAULT_PHARMACY;

                    setValues(
                      {
                        ...selectedPharmacy,
                        canChangePharmacyNomination: values.canChangePharmacyNomination,
                      },
                      true,
                    );
                  }}
                />
              </MapWrapper>
            </FormLayoutWrapper>
            {apiErrors.map((apiError) => (
              <DetailWrapper key={apiError}>
                <ErrorWrapper message={apiError} />
              </DetailWrapper>
            ))}
            {values.businessName && (
              <CheckboxWrapper>
                <Checkbox
                  type="checkbox"
                  id="canChangePharmacyNomination"
                  label="I agree to allow the nominated pharmacy to be updated for all
                   patients associated with this account"
                  checked={values.canChangePharmacyNomination}
                  onChange={handleChange}
                />
              </CheckboxWrapper>
            )}
            <ButtonWrapper>
              <Button
                option="primary"
                loading={isPatientUpdating}
                disabled={isSubmitting || !pharmacyDetailsSchema.isValidSync(values)}
                onClick={submitForm}
              >
                {registration ? 'Next' : 'Submit'}
              </Button>
            </ButtonWrapper>
          </StyledForm>
        )}
      </Formik>
    </LayoutPrivate>
  );
};

const mapState = (state: GlobalState) => ({
  account: state.user.account,
  patient: state.user.patient,
  pharmacy: state.user.pharmacy,
  preSelectedPharmacyId: state.register.preSelectedPharmacyId,
  loading: state.user.loading,
  isPharmacyUpdated: state.user.isPharmacyUpdated,
  isPatientUpdating: state.user.isPatientUpdating,
});

export default connect(mapState, {
  updatePatientPharmacy: userActions.updatePatientPharmacy,
  updatePatient: userActions.updatePatient,
  updatePatientDetails: userActions.updatePatientDetails,
  updatePharmacyDetails: userActions.updatePharmacyDetails,
  fetchServices: pharmacyServicesActions.fetchServices,
  fetchBookings: pharmacyServicesActions.fetchBookings,
  resetTemporaryStates: userActions.resetTemporaryStates,
  ...alertFunctions,
})(SelectPharmacyForm);
