import React, { useEffect } from 'react';
import { Service } from '@avicennapharmacy/managemymeds-shared';
import { connect } from 'react-redux';
import { GlobalState } from 'redux/reducers';
import * as pharmacyServicesActions from 'redux/actions/pharmacyServices';
import { Select } from 'components/Form';
import { Option } from 'react-select/src/filters';

type ServiceListProps = {
  services: Service[];
  fetched: boolean;
  selectedService: Service | null;
  selectedSubService: string | null;
  selectedServiceId: string | undefined;
  fetchServices: () => void;
  setSelectedService: (service: Service | null) => void;
  setSelectedSubService: (subService: string | null) => void;
};

const ServiceList = ({
  services,
  selectedService,
  selectedSubService,
  selectedServiceId,
  fetched,
  fetchServices,
  setSelectedService,
  setSelectedSubService,
}: ServiceListProps) => {
  useEffect(() => {
    if (!fetched) {
      fetchServices();
    }
  }, [fetched, fetchServices]);

  const options = services.map((s) => ({ value: s.id, label: s.name, data: s }));
  const subOptions = selectedService?.subServices?.map((st) => ({
    value: st.service,
    label: st.service,
  }));
  return (
    <>
      <Select
        label="Which service would you like to book?"
        options={options}
        value={options.find(({ value }) => selectedServiceId === value)}
        onChange={(val: Option) => {
          setSelectedService(val.data);
          setSelectedSubService(null);
        }}
      />
      {selectedService?.hasServiceSubTypes && (
      <Select
        key={`selectedSubService_${selectedSubService}`}
        label={`Select a ${selectedService?.name} type`}
        options={subOptions}
        value={subOptions?.find(({ value }) => selectedSubService === value)}
        onChange={({ value }: { value: string; label: string }) => {
          setSelectedSubService(value);
        }}
      />
    )}
    </>
  );
};

const mapState = (state: GlobalState) => ({
  services: state.pharmacyServices.services,
  fetched: state.pharmacyServices.servicesFetched,
  selectedServiceId: state.pharmacyServices.selectedService?.id,
  selectedService: state.pharmacyServices?.selectedService,
  selectedSubService: state.pharmacyServices?.selectedSubService,
});

export default connect(
  mapState,
  {
    fetchServices: pharmacyServicesActions.fetchServices,
    setSelectedService: pharmacyServicesActions.setSelectedService,
    setSelectedSubService: pharmacyServicesActions.setSelectedSubService,
  },
)(ServiceList);
