import { Dispatch } from 'redux';
import Axios from 'axios';
import {
  getConfigVar,
  ServiceBookingGetServicesAPIResponse,
  Action,
  ServiceBookingGetBookingsAPIResponse,
  Booking, formatAPIError, Service,
} from '@avicennapharmacy/managemymeds-shared';
import { addDays, isBefore } from 'date-fns';
import { alertFunctions } from '../alert';

export const pharmacyServicesActionTypes = {
  FETCH_SERVICES: 'PHARMACY_SERVICES_FETCH_SERVICES',
  FETCHED_SERVICES: 'PHARMACY_SERVICES_FETCHED_SERVICES',
  SET_SELECTED_SERVICE: 'SET_SELECTED_SERVICE',
  SET_SELECTED_SUBSERVICE: 'SET_SELECTED_SUBSERVICE',
  FETCH_BOOKINGS: 'PHARMACY_SERVICES_FETCH_BOOKINGS',
  FETCHED_BOOKINGS: 'PHARMACY_SERVICES_FETCHED_BOOKINGS',
  CANCELED_BOOKING: 'PHARMACY_SERVICES_CANCELED_BOOKING',
  ON_BOOKED: 'PHARMACY_SERVICES_ON_BOOKED',
  CHECKING_ENABLED: 'PHARMACY_SERVICES_CHECKING_ENABLED',
};

export const fetchBookings = () => async (dispatch: Dispatch) => {
  dispatch({ type: pharmacyServicesActionTypes.FETCH_BOOKINGS });
  let bookings: Booking[] = [];
  try {
    const {
      data,
    } = await Axios.get<ServiceBookingGetBookingsAPIResponse>(getConfigVar('serviceBookingGetBookingsEndpoint'));
    bookings = data;
  } catch (e) {
    dispatch(
      alertFunctions.showErrorAlert(
        formatAPIError(e, 'Unable to retrieve bookings at this time. Please try again.').message,
      ) as any,
    );
  }
  dispatch({ type: pharmacyServicesActionTypes.FETCHED_BOOKINGS, payload: bookings });
};

const filterAvailableServices = (s: Service) => {
  const filterStartDate = addDays(
    new Date(),
    s?.daysBookableInAdvance
      ? s?.daysBookableInAdvance
      : 0,
  );
  return s.active && (!s.endDt || isBefore(filterStartDate, new Date(s.endDt)));
};

export const fetchServices = () => async (dispatch: Dispatch) => {
  dispatch({ type: pharmacyServicesActionTypes.FETCH_SERVICES });
  try {
    let {
      data: payload,
    } = await Axios.get<ServiceBookingGetServicesAPIResponse>(getConfigVar('serviceBookingGetServicesEndpoint'));
    payload = payload.filter((service) => filterAvailableServices(service));
    dispatch({ type: pharmacyServicesActionTypes.FETCHED_SERVICES, payload });
  } catch (e) {
    dispatch(
      alertFunctions.showErrorAlert(
        formatAPIError(e, 'Unable to retrieve services list at this time. Please try again.').message,
      ) as any,
    );
  }
};

export const setSelectedService = (payload: Service | null): Action => ({
  payload,
  type: pharmacyServicesActionTypes.SET_SELECTED_SERVICE,
});

export const setSelectedSubService = (payload: string | null): Action => ({
  payload,
  type: pharmacyServicesActionTypes.SET_SELECTED_SUBSERVICE,
});

export const canceledBooking = (payload: string): Action => ({
  payload,
  type: pharmacyServicesActionTypes.CANCELED_BOOKING,
});

export const onBooked = (payload: Booking): Action => ({
  payload,
  type: pharmacyServicesActionTypes.ON_BOOKED,
});
