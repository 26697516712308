import React, { useEffect, useState } from 'react';
import { Formik, ErrorMessage } from 'formik';
import {
  Input, DetailsWrapper, DetailWrapper, ErrorWrapper,
} from 'components/Form';
import {
  getConfigVar,
  accountStatuses,
  emailSchema,
  Account,
} from '@avicennapharmacy/managemymeds-shared';
import Axios from 'axios';
import Button from 'components/Buttons/Button';
import ButtonLink from 'components/Buttons/ButtonLink';
import Routes from 'routes';
import { trackEvent } from 'utils/applicationInsights';
import Typography from 'components/Typography';
import Space from 'components/Space';
import useStateWithLocalStorage from 'hooks/useStateWithLocalStorage';
import { RouteChildrenProps } from 'react-router-dom';
import PanelMessage from 'components/PanelMessage';
import { storageKeys, storageType } from 'constants/registration';
import * as userActions from 'redux/actions/user';
import { connect } from 'react-redux';
import { GlobalState } from 'redux/reducers';
import { CustomError } from 'types/common';
import { emailIsValid } from 'utils/validation';
import Layout from '../components/Layout';
import {
  ButtonWrapper, StyledForm,
} from '../components/Shared';

type FormValues = {
  email: string;
};

type VerifyEmailStateProps = {
  invalidVerificationToken?: boolean;
  email?: string;
};

type VerifyEmailProps = {
  emailUniquenessCheck: (email: string) => void;
  updateAccountDetails: (account: Account) => void;
  createAccount: (email: string, marketingOption: boolean) => void;
  marketingEnabled: boolean;
  account: Account;
  uniquenessChecking: boolean;
  isValidEmail: boolean;
  error: CustomError | null;
} & RouteChildrenProps;

const VerifyEmail = ({
  location,
  emailUniquenessCheck,
  updateAccountDetails,
  marketingEnabled,
  account,
  uniquenessChecking,
  isValidEmail,
  createAccount,
  error,
}: VerifyEmailProps) => {
  const { state }: { state?: VerifyEmailStateProps | null } = location;
  const [infoMessage, setInfoMessage] = useState('');
  const [verifyError, setVerifyError] = useState(!!state?.invalidVerificationToken);
  const [showLoginButton, setShowLoginButton] = useState(false);
  const [showResendEmailButton, setShowResendEmailButton] = useState(false);
  const [loginEmail, setLoginEmail] = useState('');
  const [resendButtonText, setResendButtonText] = useState('');
  const [buttonText, setButtonText] = useState('Continue');
  const [loading, setLoading] = useState(false);
  const [, setEmailValue] = useStateWithLocalStorage(storageKeys.EMAIL, storageType.DEFAULT);
  const [, setAccountIdValue] = useStateWithLocalStorage(storageKeys.ACCOUNT_ID, storageType.DEFAULT);

  useEffect(() => {
    if (uniquenessChecking) {
      setButtonText('Checking');
    } else if (loading) {
      setButtonText('Sending');
    } else {
      setButtonText('Continue');
    }
  }, [uniquenessChecking, loading]);

  useEffect(() => {
    if (state?.email) {
      updateAccountDetails({ ...account, email: state?.email });
      emailUniquenessCheck(state?.email);
    }
  }, []);

  return (
    <Layout>
      <Space size={60} />
      <Formik
        enableReinitialize
        initialValues={{ email: state?.email || '' }}
        validationSchema={emailSchema}
        onSubmit={async ({ email }: FormValues, { setSubmitting, setErrors }) => {
          setSubmitting(true);
          setVerifyError(false);
          trackEvent('SetDateOfBirthInStorage');
          trackEvent('SetEmailInStorage');
          setEmailValue(email);
          setAccountIdValue(account.id);
          // First check if an account with email already exists by checking for an account status.
          try {
            if (!emailSchema.isValidSync({ email }) || !isValidEmail) {
              // If an account exists and it is created or verfified, prompt the user to verify their email.
              if (account.accountStatus && account.accountStatus !== accountStatuses.ACTIVE) {
                setInfoMessage(
                  'Oops! An account already exists for that email address,'
                  + ' please click below to resend confirmation email or check email for existing verification link.',
                );
                setResendButtonText('Resend email confirmation');
                setShowResendEmailButton(true);
                trackEvent(
                  'SendVerificationEmailError', { reason: `Account already exists and status is ${account.accountStatus}` },
                );
                return;
              }

              if (account.accountStatus === accountStatuses.ACTIVE) {
                // Otherwise if an account exists and it is active, prompt the user to login.
                setInfoMessage(
                  'An account already exists with this email address. If this is your account'
                  + ' then please click Login below.',
                );
                setLoginEmail(email);
                setShowLoginButton(true);
                trackEvent('SendVerificationEmailError', { reason: 'Account already exists and is verified' });
                return;
              }
            } else {
              createAccount(account?.email, marketingEnabled);
              setInfoMessage(
                'A verification email has been sent to your email inbox with a link to verify'
                + ' your account. Please open it to continue with registration.',
              );
              return;
            }
          } catch (e) {
            setErrors({ email: 'There was an error, please try again or contact help & support.' });
            trackEvent('SendVerificationEmailError', { e });
          }
          setSubmitting(false);
        }}
      >
        {({
          values, isSubmitting, handleChange, setSubmitting, setErrors,
        }) => (
          <>
            <Typography fontStyle="h2" margin>
              Your Details {isSubmitting}
            </Typography>
            <Space size={30} />
            {verifyError && !infoMessage && (
              <PanelMessage
                type="error"
                message="Your email address could not be verified. Please try again later. If it continues, please contact us."
              />
            )}
            <StyledForm aria-label="email-form">
              <DetailsWrapper>
                <DetailWrapper>
                  <Input
                    label="Email"
                    id="email"
                    name="email"
                    placeholder="john@email.com"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setShowLoginButton(false);
                      setShowResendEmailButton(false);
                      setInfoMessage('');
                      handleChange(e);
                      updateAccountDetails({ ...account, email: e.target.value });
                      if (emailIsValid(e.target.value)) {
                        emailUniquenessCheck(e.target.value);
                      }
                    }}
                    value={values.email}
                    disabled={isSubmitting}
                  />
                </DetailWrapper>
                <ErrorMessage name="email">{(message) => <ErrorWrapper message={message} />}</ErrorMessage>
                {error?.message && (
                  <PanelMessage
                    type="error"
                    message={error?.message}
                  />
                )}
                {infoMessage && (
                  <Typography fontStyle="bodyBold" margin>
                    {infoMessage}
                  </Typography>
                )}
              </DetailsWrapper>
              <Space />
              <ButtonWrapper>
                {showLoginButton && (
                  <ButtonLink option="secondary" to={{ pathname: Routes.LOGIN, state: { email: loginEmail } }}>
                    Continue
                  </ButtonLink>
                )}
                {showResendEmailButton && (
                  <Button
                    option="primary"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    onClick={async () => {
                      setSubmitting(true);
                      setLoading(true);
                      setErrors({ email: undefined });
                      // Allow a user with an unverified account to send another verification email.
                      try {
                        await Axios.get(`${getConfigVar('resendVerificationEndpoint')}/${values.email}`);
                        setInfoMessage(
                          'A verification email has been sent to your email inbox with a link to verify'
                          + ' your account. Please open it to continue with registration.',
                        );
                        setShowResendEmailButton(false);
                        trackEvent('ResendVerificationEmail');
                      } catch (e) {
                        setErrors({ email: 'There was an error, please try again or contact help & support.' });
                        trackEvent('ResendVerificationEmailError', { e });
                      }
                      setLoading(false);
                      setSubmitting(false);
                    }}
                  >
                    {resendButtonText}
                  </Button>
                )}
                {!showLoginButton && !showResendEmailButton && !infoMessage && (
                  <Button
                    option="primary"
                    type="submit"
                    loading={isSubmitting}
                    disabled={isSubmitting || !emailSchema.isValidSync(values) || uniquenessChecking}
                  >
                    {buttonText}
                  </Button>
                )}
              </ButtonWrapper>
            </StyledForm>
          </>
        )}
      </Formik>
    </Layout>
  );
};

const mapState = (state: GlobalState) => ({
  account: state.user.account,
  marketingEnabled: state.user.marketingEnabled,
  uniquenessChecking: state.user.uniquenessChecking,
  isValidEmail: state.user.isValidEmail,
  error: state.user.error,
});

export default connect(mapState, {
  emailUniquenessCheck: userActions.emailUniquenessCheck,
  updateAccountDetails: userActions.updateAccountDetails,
  createAccount: userActions.createAccount,
})(VerifyEmail);
