import React from 'react';
import { Service, Pharmacy, PromotedServices } from '@avicennapharmacy/managemymeds-shared';
import PromotionCard from '../PromotionCard';

type PromotedServicesProps = {
  onClick: (s: Service) => void;
  pharmacy: Pharmacy | null;
};

const PromotedService = ({ onClick, pharmacy }: PromotedServicesProps) => {
  const { hasPromoteServiceCard, promotedServices } = pharmacy || {};

  if (!hasPromoteServiceCard || !promotedServices) {
    return null;
  }

  return (
    <>
      {promotedServices?.map((promotedService: PromotedServices) => {
        const showPromotion = Boolean(
          hasPromoteServiceCard && promotedService?.serviceId && promotedService?.serviceCardText,
        );

        return (
          <PromotionCard
            onClick={onClick}
            showPromotion={showPromotion}
            promoteServiceId={promotedService.serviceId}
            promoteServiceCardText={promotedService.serviceCardText}
          />
        );
      })}
    </>
  );
};

export default PromotedService;
