import React, { useState, useEffect } from 'react';
import { IconMyServices } from 'icons';
import Typography from 'components/Typography';
import ButtonLink from 'components/Buttons/ButtonLink';
import Routes from 'routes';
import Space from 'components/Space';
import { trackEvent } from 'utils/applicationInsights';
import {
  formatAPIError,
  Service,
  ServiceBookingGetServicesAPIResponse,
  getConfigVar,
  COVID19_VAC_AND_BOOSTER_SERVICE,
} from '@avicennapharmacy/managemymeds-shared';
import Axios from 'axios';
import Card from '../Card';

type PromotionCardProps = {
  onClick: (s: Service) => void;
  showPromotion: boolean;
  promoteServiceId?: string;
  promoteServiceCardText?: string;
};
const PromotionCard = ({
  onClick,
  showPromotion,
  promoteServiceId,
  promoteServiceCardText,
}: PromotionCardProps) => {
  const [service, setService] = useState<Service | undefined>(undefined);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchService = async () => {
      try {
        const { data: services } = await Axios.get<ServiceBookingGetServicesAPIResponse>(
          getConfigVar('serviceBookingGetServicesEndpoint'),
        );
        const promotedService = services.find((s) => s.id === promoteServiceId);

        setService(promotedService);
        setError('');
      } catch (err) {
        setError(formatAPIError(err, 'There was a problem fetching the service').message);
        trackEvent('GetPromotedServiceError', { error: err });
      }
    };

    if (showPromotion) {
      if (promoteServiceId !== COVID19_VAC_AND_BOOSTER_SERVICE.id) {
        fetchService();
      } else {
        setService(COVID19_VAC_AND_BOOSTER_SERVICE);
      }
    }
  }, [promoteServiceId, showPromotion]);

  if (!showPromotion || error || !service || !service?.externalName) {
    return null;
  }

  return (
    <>
      <Card icon={IconMyServices} title={service?.externalName}>
        <Typography fontStyle="body">{promoteServiceCardText}</Typography>
        <Space size={20} />
        <ButtonLink
          option="primary"
          to={Routes.PHARMACY_SERVICES}
          onClick={() => {
            onClick(service);
            trackEvent('ViewPromotedService');
          }}
        >
          Book now
        </ButtonLink>
      </Card>
    </>
  );
};

export default PromotionCard;
