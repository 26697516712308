import {
  Action,
  Account,
  Patient,
  Pharmacy,
  Group,
  DEFAULT_ACCOUNT,
  DEFAULT_PATIENT,
  DEFAULT_PHARMACY,
  PatientIntegrationSettings,
  LinkedPatient,
} from '@avicennapharmacy/managemymeds-shared';
import { CustomError } from 'types/common';
import { userActionTypes } from '../../actions/user';

export type userStateType = {
  account: Account;
  patient: Patient;
  createPatientLoading: boolean;
  linkedPatients: LinkedPatient[];
  pharmacy: Pharmacy;
  group: Group | null;
  integrationSettings: PatientIntegrationSettings | null;
  userDetailsFetched: boolean;
  userDetailsLoading: boolean;
  refetched: boolean;
  isValidEmail: boolean;
  uniquenessChecking: boolean;
  error: CustomError | null;
  isEmailVerified: boolean;
  verificationError: boolean;
  isLinkingSuccess: boolean;
  shouldLogin: boolean;
  signInCompleted: boolean;
  isNHSLoginProcessing: boolean;
  nhsAuthorizationCompleted: boolean;
  signInProcessing: boolean;
  loading: boolean;
  isPharmacyUpdated: boolean;
  isNhsLogin: boolean;
  isPatientUpdating: boolean;
  loginError: string;
  isConnectionSuccess: boolean;
  isConnectioninProgress: boolean;
  marketingEnabled: boolean;
  im1ConnectionError: string;
};

export const initialUserState: userStateType = {
  account: DEFAULT_ACCOUNT,
  patient: DEFAULT_PATIENT,
  createPatientLoading: false,
  linkedPatients: [],
  pharmacy: DEFAULT_PHARMACY,
  group: null,
  integrationSettings: null,
  userDetailsFetched: false,
  userDetailsLoading: false,
  refetched: false,
  isValidEmail: true,
  uniquenessChecking: false,
  error: null,
  isEmailVerified: false,
  verificationError: false,
  isLinkingSuccess: false,
  shouldLogin: false,
  signInCompleted: false,
  isNHSLoginProcessing: false,
  nhsAuthorizationCompleted: false,
  signInProcessing: false,
  loading: false,
  isPharmacyUpdated: false,
  isNhsLogin: false,
  isPatientUpdating: false,
  loginError: '',
  isConnectionSuccess: false,
  isConnectioninProgress: false,
  marketingEnabled: true,
  im1ConnectionError: '',
};

export default (state = initialUserState, action: Action): userStateType => {
  switch (action.type) {
    case userActionTypes.FETCH:
      return { ...state, userDetailsLoading: true };
    case userActionTypes.FETCHED:
      return {
        ...state,
        account: action.payload.account || DEFAULT_ACCOUNT,
        patient: action.payload.patient || DEFAULT_PATIENT,
        pharmacy: action.payload.pharmacy || DEFAULT_PHARMACY,
        linkedPatients: action.payload.linkedPatients || [],
        group: action.payload.group || null,
        integrationSettings: action.payload.integrationSettings || null,
        userDetailsFetched: true,
        userDetailsLoading: false,
      };
    case userActionTypes.REFETCHED:
      return { ...state, refetched: true };
    case userActionTypes.RESET_REFETCHED:
      return { ...state, refetched: false };
    case userActionTypes.UPDATE_ACCOUNT_DETAILS:
      return { ...state, account: action.payload };
    case userActionTypes.UPDATE_PATIENT_DETAILS:
      return { ...state, patient: action.payload };
    case userActionTypes.UPDATE_PHARMACY_DETAILS:
      return { ...state, pharmacy: action.payload };
    case userActionTypes.UPDATE_PATIENT_INTEGRATION_DETAILS:
      return { ...state, integrationSettings: action.payload };
    case userActionTypes.UPDATE_PATIENT_INTEGRATION:
      return { ...state, patient: { ...state.patient, patientIntegration: action.payload } };
    case userActionTypes.EMAIL_UNIQUENESS_CHECKING:
      return {
        ...state,
        uniquenessChecking: action.payload,
      };
    case userActionTypes.EMAIL_UNIQUENESS_CHECK:
      return {
        ...state,
        isValidEmail: action.payload.accountStatus === true,
        account: {
          ...state.account,
          id: action.payload.id,
          accountStatus: action.payload.accountStatus !== true ? action.payload.accountStatus : null,
          b2CObjectId: action.payload.b2CObjectId,
        },
        uniquenessChecking: false,
      };
    case userActionTypes.CREATE_ACCOUNT:
      return {
        ...state,
        account: action.payload,
      };
    case userActionTypes.USER_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case userActionTypes.RESET_USER_ERROR:
      return {
        ...state,
        error: null,
      };
    case userActionTypes.EMAIL_VERIFICATION:
      return {
        ...state,
        account: {
          ...state.account,
          id: action.payload.id,
          email: action.payload.email,
        },
        isEmailVerified: true,
      };
    case userActionTypes.EMAIL_VERIFICATION_ERROR:
      return {
        ...state,
        verificationError: true,
      };
    case userActionTypes.ACCOUNT_STATUS_CHECK:
      return {
        ...state,
        account: {
          ...state.account,
          accountStatus: action.payload.accountStatus,
        },
      };
    case userActionTypes.MANUAL_LINKING_COMPLETED:
      return {
        ...state,
        isLinkingSuccess: action.payload.isSuccess,
        shouldLogin: action.payload.requiresLogin,
      };
    case userActionTypes.CREATE_B2CUSER:
      return {
        ...state,
        account: action.payload,
      };
    case userActionTypes.SIGN_IN_COMPLETED:
      return {
        ...state,
        signInProcessing: false,
        signInCompleted: action.payload,
      };
    case userActionTypes.CREATE_PATIENT:
      return {
        ...state,
        patient: action.payload,
      };
    case userActionTypes.CREATE_PATIENT_LOADING:
      return {
        ...state,
        createPatientLoading: action.payload,
      };
    case userActionTypes.RESET_TEMPORARY_STATES:
      return {
        ...state,
        uniquenessChecking: false,
        isValidEmail: true,
        isEmailVerified: false,
        verificationError: false,
        signInCompleted: false,
        signInProcessing: false,
        isNHSLoginProcessing: false,
        isLinkingSuccess: false,
        shouldLogin: false,
        isNhsLogin: false,
        loading: false,
        marketingEnabled: true,
        nhsAuthorizationCompleted: false,
      };
    case userActionTypes.NHS_LINKING_PROCESSING:
      return {
        ...state,
        isNHSLoginProcessing: action.payload,
      };
    case userActionTypes.NHS_AUTHORIZATION_COMPLETED:
      return {
        ...state,
        nhsAuthorizationCompleted: true,
      };
    case userActionTypes.NHS_LINKING_COMPLETED:
      return {
        ...state,
        account: {
          ...state.account,
          id: action.payload.accountId,
        },
        patient: {
          ...state.patient,
          id: action.payload.patientId,
          accountId: action.payload.accountId,
        },
        isLinkingSuccess: true,
        isNHSLoginProcessing: false,
        isNhsLogin: true,
      };
    case userActionTypes.SIGNIN_PROCESSING:
      return {
        ...state,
        signInProcessing: action.payload,
      };
    case userActionTypes.USER_PROCESS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case userActionTypes.UPDATE_PATIENT_PHARMACY:
      return {
        ...state,
        patient: action.payload,
        isPharmacyUpdated: true,
      };
    case userActionTypes.UPDATE_PATIENT:
      return {
        ...state,
        patient: action.payload,
        isPharmacyUpdated: true,
        isPatientUpdating: false,
      };
    case userActionTypes.UPDATING_PATIENT:
      return {
        ...state,
        isPatientUpdating: true,
      };
    case userActionTypes.LOGIN_ERROR:
      return {
        ...state,
        loginError: action.payload,
        signInProcessing: false,
      };
    case userActionTypes.IM1_CONNECTION_PROCESS_STARTED:
      return {
        ...state,
        isConnectioninProgress: true,
        im1ConnectionError: '',
      };
    case userActionTypes.IM1_CONNECTION_PROCESS_COMPLETED:
      return {
        ...state,
        isLinkingSuccess: action.payload.isSuccess,
        shouldLogin: action.payload.requiresLogin,
        isConnectioninProgress: false,
        isConnectionSuccess: true,
      };
    case userActionTypes.IM1_CONNECTION_PROCESS_ERROR:
      return {
        ...state,
        im1ConnectionError: action.payload.message,
        isConnectioninProgress: false,
        isConnectionSuccess: false,
      };
    case userActionTypes.CHANGE_MARKETING_OPTION:
      return {
        ...state,
        marketingEnabled: action.payload,
      };
    default:
      return state;
  }
};
