import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { GlobalState } from 'redux/reducers';
import {
  ServiceAvailability,
  getConfigVar,
  ServiceBookingGetAvailabilityAPIResponse,
  Service,
  Booking,
  formatDate,
  BookingPlatform,
  getServiceStartDate,
  Availability,
  CalendarCustomHeader,
  months,
  getDaysOfMonth,
} from '@avicennapharmacy/managemymeds-shared';
import Button from 'components/Buttons/Button';
import Axios from 'axios';
import { DatePicker, Select } from 'components/Form';
import styled from 'styled-components';
import * as pharmacyServicesActions from 'redux/actions/pharmacyServices';
import { alertFunctions, AlertFunctionProps } from 'redux/actions/alert';
import Typography from 'components/Typography';
import { trackEvent } from 'utils/applicationInsights';
import {
  addMonths, endOfMonth, getMonth, getYear, isBefore, isSameDay, startOfMonth,
} from 'date-fns';
import ButtonLinkExternal from 'components/Buttons/ButtonLinkExternal';
import LoadingSpinner from 'components/LoadingSpinner';
import ServiceList from './components/ServiceList';

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type SelectATimeOption = { value: ServiceAvailability; label: string };

type ServiceBookProps = {
  selectedService: Service | null;
  selectedSubService: string | null;
  onBooked: (newBooking: Booking) => void;
  setSelectedService: (service: Service | null) => void;
  setSelectedSubService: (subService: string | null) => void;
} & AlertFunctionProps;

const ServicesBook = ({
  selectedService,
  selectedSubService,
  onBooked,
  hideAlert,
  showErrorAlert,
  setSelectedService,
  setSelectedSubService,
}: ServiceBookProps) => {
  const [loading, setLoading] = useState(false);
  const [availability, setAvailability] = useState<Availability>({});
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedAvailability, setSelectedAvailability] = useState<ServiceAvailability | null>(null);
  const [unavailableDates, setUnavailableDates] = useState<Date[]>([]);
  const [disabledDates, setDisabledDates] = useState<Date[]>([]);
  const [booking, setBooking] = useState(false);
  const [fromDate, setFromDate] = useState(selectedService ? getServiceStartDate(selectedService, selectedSubService) : new Date());
  const [lastLoadedMonth, setLastLoadedMonth] = useState<Date | null>(null);
  const [page, setPage] = useState<'serviceDate' | 'time' | 'bookingSuccess'>('serviceDate');

  const checkServiceAvailability = async (dateFrom: Date) => {
    try {
      const daysOfMonth = getDaysOfMonth(dateFrom);
      setDisabledDates(daysOfMonth);
      setLoading(true);
      hideAlert();
      if (lastLoadedMonth && isBefore(new Date(dateFrom), endOfMonth(lastLoadedMonth))) {
        setLoading(false);
        return;
      }

      const { data } = await Axios.get<ServiceBookingGetAvailabilityAPIResponse>(
        `${getConfigVar('serviceBookingGetAvailabilityEndpoint')}/${
          selectedService?.id
        }?from=${dateFrom.toUTCString()}&to=${endOfMonth(dateFrom).toUTCString()}`,
      );

      data.days.map(({ availableDate }) => {
        const index = daysOfMonth.findIndex((x) => isSameDay(x, new Date(availableDate)));
        if (index !== -1) {
          daysOfMonth.splice(index, 1);
        }
        return daysOfMonth;
      });

      availability[`${getMonth(dateFrom)}${getYear(dateFrom)}`] = {
        availability: data.availability,
        days: data.days,
        unavailableDates: daysOfMonth,
      };

      unavailableDates.push(...daysOfMonth);
      setUnavailableDates(unavailableDates);
      setAvailability(availability);
      setLastLoadedMonth(startOfMonth(new Date(dateFrom)));
      setPage('time');
    } catch (e) {
      if (e.response.status === 422) {
        showErrorAlert(e.response.data.message);
      } else {
        showErrorAlert('Unable to search for service availability at this time. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedService && selectedService?.hasServiceSubTypes && !selectedSubService) {
      return;
    }

    if (selectedService && !selectedService.externalLink) {
      checkServiceAvailability(fromDate);
    } else if (selectedService && selectedService.externalLink) {
      setPage('time');
    }
  }, []);

  const book = async () => {
    if (selectedAvailability) {
      setBooking(true);
      const { start } = selectedAvailability;
      try {
        const { data } = await Axios.post<Booking>(getConfigVar('serviceBookingBookEndpoint'), {
          serviceId: selectedService?.id,
          start,
          platform: BookingPlatform.WebApp,
          serviceSubType: selectedSubService,
        });
        // todo: capture the service as well
        trackEvent('BookPharmacyService', { start });
        onBooked(data);
        setPage('bookingSuccess');
      } catch (error) {
        if (error.response.status === 422) {
          showErrorAlert(error.response.data.message);
        } else {
          showErrorAlert('Unable to book service at this time.');
        }
        trackEvent('BookPharmacyServiceError', { error });
      }
      setBooking(false);
    }
  };

  const monthChange = (date: Date, compensate: number) => {
    if (selectedService) {
      const serviceStartDate = getServiceStartDate(selectedService, selectedSubService);
      const calendarDate = addMonths(date, compensate);
      const dateFrom = isBefore(calendarDate, serviceStartDate) ? serviceStartDate : startOfMonth(calendarDate);
      setFromDate(dateFrom);
      checkServiceAvailability(dateFrom);
    }
  };

  return (
    <>
      {page === 'serviceDate' && (
        <>
          <ServiceList />
          <Button
            onClick={() => (selectedService ? checkServiceAvailability(getServiceStartDate(selectedService, selectedSubService)) : null)}
            disabled={selectedService?.hasServiceSubTypes ? (!selectedService || !selectedSubService) : !selectedService}
            loading={loading}
            option="primary"
          >
            Search
          </Button>
        </>
      )}
      {page === 'time' && (
        <>
          <Title>
            <Typography fontStyle="h2" margin>
              {selectedService?.name}
              {selectedSubService ? `: ${selectedSubService}` : ''}
            </Typography>
            <Button
              option="edit"
              onClick={() => {
                setSelectedDate(null);
                setSelectedAvailability(null);
                setUnavailableDates([]);
                setAvailability({});
                setLastLoadedMonth(null);
                setPage('serviceDate');
              }}
            >
              Edit
            </Button>
          </Title>
          <Typography fontStyle="body" margin>
            {selectedService?.externalDescription}
          </Typography>
          {Boolean(selectedService?.pricingInformation) && (
            <Typography fontStyle="body" margin>
              <Typography fontStyle="bodyBold">Pricing information: </Typography>
              {selectedService?.pricingInformation}
            </Typography>
          )}
          {!selectedService?.externalLink ? (
            <>
              <DatePicker
                label="Select a date"
                renderCustomHeader={({
                  date,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }: CalendarCustomHeader) => (
                  <>
                    <div
                      style={{
                        margin: 10,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <button
                        style={{
                          border: 'none',
                          margin: '0px 30px',
                          fontSize: 16,
                          background: 'none',
                        }}
                        onClick={() => {
                          decreaseMonth();
                          monthChange(date, -1);
                        }}
                        type="button"
                        disabled={loading || prevMonthButtonDisabled}
                      >
                        {'<'}
                      </button>
                      {`${months[getMonth(date)]} ${getYear(date)}`}

                      <button
                        style={{
                          border: 'none',
                          margin: '0px 30px',
                          fontSize: 16,
                          background: 'none',
                        }}
                        onClick={() => {
                          increaseMonth();
                          monthChange(date, 1);
                        }}
                        type="button"
                        disabled={loading || nextMonthButtonDisabled}
                      >
                        {'>'}
                      </button>
                    </div>
                    {loading && <LoadingSpinner name="calSpinner" small center />}
                  </>
                )}
                dateFormat="dd/MM/yyyy"
                placeholderText="Select date..."
                showPopperArrow={false}
                excludeDates={loading ? disabledDates : unavailableDates}
                selected={selectedDate}
                minDate={selectedService ? getServiceStartDate(selectedService, selectedSubService) : undefined}
                maxDate={selectedService?.endDt ? new Date(selectedService.endDt) : undefined}
                onChange={(date: Date) => {
                  setSelectedAvailability(null);
                  setSelectedDate(date);
                }}
              />
              <div>
                <Select
                  label="Select a time"
                  value={
                    selectedAvailability
                      ? {
                        value: selectedAvailability,
                        label: formatDate(selectedAvailability.start, 'time'),
                      }
                      : null
                  }
                  options={selectedDate && availability[`${getMonth(selectedDate)}${getYear(selectedDate)}`] ? availability[
                    `${getMonth(selectedDate)}${getYear(selectedDate)}`
                  ].availability?.reduce<SelectATimeOption[]>((timeSlots, curr) => {
                    if (curr.isAvailable && isSameDay(new Date(selectedDate), new Date(curr.start))) {
                      timeSlots.push({ value: curr, label: formatDate(curr.start, 'time') });
                    }
                    return timeSlots;
                  }, []) : []}
                  isDisabled={booking || !selectedDate}
                  onChange={({ value }: SelectATimeOption) => setSelectedAvailability(value)}
                />
                <Button
                  onClick={book}
                  option="primary"
                  disabled={
                    booking
                    || !selectedAvailability
                    || (Boolean(selectedService?.hasServiceSubTypes) && !selectedSubService)
                  }
                  loading={booking}
                >
                  Book
                </Button>
              </div>
            </>
          ) : (
            <>
              <ButtonLinkExternal
                option="primary"
                href={selectedService.externalLink}
                target="__blank"
                onClick={() => trackEvent('PromotionLink', { route: selectedService.externalLink })}
              >
                {selectedService.externalLinkText}
              </ButtonLinkExternal>
            </>
          )}
        </>
      )}
      {page === 'bookingSuccess' && (
        <>
          <Title>
            <Typography fontStyle="body" margin>
              Your booking has been confirmed.
            </Typography>
          </Title>
          <Button
            option="primary"
            onClick={() => {
              setPage('serviceDate');
              setSelectedDate(null);
              setAvailability({});
              setSelectedAvailability(null);
              setSelectedService(null);
              setSelectedSubService(null);
              setLastLoadedMonth(null);
            }}
          >
            Book another service
          </Button>
        </>
      )}
    </>
  );
};

const mapProps = (state: GlobalState) => ({
  loading: state.pharmacyServices.servicesLoading,
  selectedService: state.pharmacyServices.selectedService,
  selectedSubService: state.pharmacyServices.selectedSubService,
});

export default connect(mapProps, {
  onBooked: pharmacyServicesActions.onBooked,
  setSelectedService: pharmacyServicesActions.setSelectedService,
  setSelectedSubService: pharmacyServicesActions.setSelectedSubService,
  ...alertFunctions,
})(ServicesBook);
